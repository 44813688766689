// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  // production
  // production: true,
  // API_URL: 'https://api.ezybooks.net/',
  // DOC_URL: 'https://ezybooks.net/docs/files',
  // TEMPLATE_URL: 'https://ezybooks.net/docs/importtemplates',

  //test server
  production: false,
  API_URL: 'https://ezbadminapi.sanmolappsdev.com/',
  DOC_URL: 'https://ezb1.sanmolappsdev.com/docs/files',
  TEMPLATE_URL: 'http://ezbadminapi.sanmolappsdev.com/docs/importtemplates',

  //local server
  // production: false,
  // API_URL: 'https://localhost:44310/',
  // DOC_URL: 'https://eb.sanmolappspilot.com/docs/files',
  // TEMPLATE_URL: 'https://eb.sanmolappspilot.com/docs/importtemplates',

  APP_NAME: 'EzyBooksAdmin-'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
